
/*for date check value*/
function checkValue(str, max) {
  if (str.charAt(0) !== '0' || str == '00') {
    var num = parseInt(str);
    if (isNaN(num) || num <= 0 || num > max) num = 1;
    str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
  };
  return str;
};

function showDeleteModal(value, ids) {
  document.getElementById('deleted_user_id').value = ids
  $('#delete-modal').modal(value)
}

function moveActiveLink() {
  var current = location.pathname;
    $('.nav li a').each(function(){
        var $this = $(this);
        // if the current path is like this link, make it active
        if($this.attr('href').indexOf(current) !== -1 && current !== '/'){
            $this.parent().addClass('active');
        }
    })
}
/*for date check*/

function emptyData() {
  $('.selected-shipper').val(function() {
    this.value = ''
  });
  $('.info-box').append([
    '<span></span>',
    '<br/>',
    '<small>' +
      '<br/>' +
      '<br/>' +
      '<br/>' +
      '<b></b>' +
      '<br/>' +
      '<br/>' +
    '</small>'
  ])
}

function populateData(val, user_id) {
  if (val) {
    $('.create-contact-save').hide();
    $('.edit-contact-save').show();
    $('.create-label').hide();
    $('.edit-label').show();
    document.getElementById('user_id').value = user_id
    document.getElementById('contact_company_name').value = val.company_name
    document.getElementById('contact_tax_number').value = val.tax_number
    document.getElementById('contact_address').value = val.address
    document.getElementById('contact_secondary_address').value = val.secondary_address
    document.getElementById('contact_country').value = val.country
    document.getElementById('contact_city').value = val.city
    document.getElementById('contact_province').value = val.province
    document.getElementById('contact_postal_code').value = val.postal_code
    document.getElementById('contact_title').value = val.title
    document.getElementById('contact_first_name').value = val.first_name
    document.getElementById('contact_last_name').value = val.last_name
    document.getElementById('contact_email').value = val.email
    document.getElementById('contact_phone_number').value = val.phone_number
  }
}

$(window).scroll(function() {

/*animate on the element getting into view*/
  $('[class*="wscroll-"]').each(function(){
    var pos = $(this).offset().top;

    var winTop = $(window).scrollTop();
    if (pos < winTop + 600) {
		var temp = $(this).attr('class');
		temp = temp.replace('wscroll-','');
		$(this).attr('class',temp);
    }
  });


/*remove hidden & animate on the element getting into view*/
  $('[class*="hscroll-"]').each(function(){
    var pos = $(this).offset().top;

    var winTop = $(window).scrollTop();
    if (pos < winTop + 600) {
		$(this).css('visibility','visible');
		var temp = $(this).attr('class');
		temp = temp.replace('hscroll-','');
		$(this).attr('class',temp);
		//$(this).addClass('infinite');
    }
  });
});

function forcenumber(text){
	return text.replace(/[^0-9]/g,'');
}

function setAlert () {
  if (localStorage.getItem("status") !== null && localStorage.getItem("message") !== null) {
    $('.display-alert').addClass(localStorage.getItem("status"))
    $("#custom-alert").show();
    var message = localStorage.getItem("message")
    var $appendErr = $('<small>' + message + '</small>');
    $appendErr.appendTo('.display-alert');
    setTimeout(function(){
      $("#custom-alert").hide();
      localStorage.clear();
    }, 6000);
  }
}

function forcenumberdeci(text){
	return text.replace(/[^0-9\.]/g,'');
}

$( document ).ready(function() {
  setAlert()
	/*add hidden to parent alert instead of removing it*/
	$('.hidclose').on("click",function(e){
		e.preventDefault();
		$(this).parent(".alert").addClass("hidden");
  });

	/*add hidden to all hscroll element*/
   $('[class*="hscroll-"]').each(function(){
    $(this).css('visibility','hidden');
  });

  $(window).scroll();

  /*button to switch city*/
  $("#switchcity").on("click",function(e){
	 e.preventDefault();

	 var asalalamat = $('#asalalamat').val();
	 var asalprovinsi = $('#asalprovinsi').val();
	 var asalnegara = $('#asalnegara').val();
	 var asalkota = $('#asalkota').val();
	 var asalpos = $('#asalpos').val();

	 $('#asalalamat').val($('#tujuanalamat').val());
	 $('#asalprovinsi').val($('#tujuanprovinsi').val());
	 $('#asalnegara').val($('#tujuannegara').val());
	 $('#asalkota').val($('#tujuankota').val());
	 $('#asalpos').val($('#tujuanpos').val());

	 $('#tujuanalamat').val(asalalamat);
	 $('#tujuanprovinsi').val(asalprovinsi);
	 $('#tujuannegara').val(asalnegara);
	 $('#tujuankota').val(asalkota);
	 $('#tujuanpos').val(asalpos);
  });

  $('.btn-profile-close').on('click', function() {
    $('.modal').modal('hide');
    $('.text-error').remove();
  })

  $('input[type=radio][name=audience]').change(function() {
    var selValue = $('input[name=audience]:checked').val();
    if (selValue === 'consumer') {
      $('.radio label').removeClass('active');
      $(this).parent().addClass('active');
      $('.bank-transfer-form').show();
      $('.credit-form').hide();
    } else {
      $('.radio label').removeClass('active');
      $(this).parent().addClass('active');
      $('.bank-transfer-form').hide();
      $('.credit-form').show();
    }

  })

  /*forcenumber*/

  $(".forcenumber").on("keyup",function(){
	  this.value = forcenumber(this.value);
  })

  $(".forcenumberdeci").on("keyup",function(){
	  this.value = forcenumberdeci(this.value);
  })

  $('.topup-profile').on('click', function() {
    $('#topup-modal').modal('show');
  })

  // $('.btn-profile-close').on('click', function() {
  //   $('.btn-topup-close').modal('hide');
  // })

  $('.edit-profile').on('click', function(e) {
    path = window.location.href
    if (path.match(/contacts/)) {
      $('.create-contact-save').show();
      $('.edit-contact-save').hide();
      $('.create-label').show();
      $('.edit-label').hide();
      document.getElementById('user_id').value = null
      document.getElementById('contact_company_name').value = null
      document.getElementById('contact_tax_number').value = null
      document.getElementById('contact_address').value = null
      document.getElementById('contact_secondary_address').value = null
      document.getElementById('contact_country').value = null
      document.getElementById('contact_city').value = null
      document.getElementById('contact_province').value = null
      document.getElementById('contact_postal_code').value = null
      document.getElementById('contact_title').value = null
      document.getElementById('contact_first_name').value = null
      document.getElementById('contact_last_name').value = null
      document.getElementById('contact_email').value = null
      document.getElementById('contact_phone_number').value = null
    }
    $('#edit-modal').modal('show');
  })

  $('.contact-search').on('keypress', function(e) {
    if (e.which === 13) {
      var searchParams = $('.contact-search').val()
      if (searchParams !== '') {
        window.location.href = '/contacts?name=' + searchParams;
      } else {
        window.location.href = '/contacts';
      }
    }
  })

  moveActiveLink();

  $('.image-admin').on('click', function(e) {
    if ($(this).attr('src')) {
      window.open($(this).attr('src'), '_blank')
    }
  })

  $('.btn-profile-save').on('click', function(e) {
    var formData = new FormData($('.form-ajax-login')[0]);
      $.ajax({
      type: "POST",
      url: '/users/update_profile',
      data: formData,
      processData: false,
      contentType: false,
      success: function(result) {
        localStorage.setItem("status", "alert-success")
        localStorage.setItem("message", "Data berhasil disimpan")
        location.reload();
      },
      error: function(error){
        var $appendErr = $('<small class="text-error">' + error.responseJSON.message + '</small>');
        $appendErr.appendTo('.error-message-info');
      }
    });
  })

  $('.btn-topup-save').on('click', function() {
    var formData = new FormData($('.form-ajax-topup')[0]);
    $.ajax({
      type: "POST",
      url: '/users/top_up',
      data: formData,
      processData: false,
      contentType: false,
      success: function(result) {
        localStorage.setItem("status", "alert-success")
        localStorage.setItem("message", "Data berhasil disimpan")
        location.reload();
      },
      error: function(error){
        var $appendErr = $('<small class="text-error">' + error.responseJSON.message + '</small>');
        $appendErr.appendTo('.error-message-info');
      }
    })
  })

  $('.btn-topups').on('click', function(e) {
    var formData = new FormData($('.form-ajax-topups')[0]);
    $.ajax({
      type: "POST",
      url: '/users/top_up',
      data: formData,
      processData: false,
      contentType: false,
      success: function(result) {
        localStorage.setItem("status", "alert-success")
        localStorage.setItem("message", result.message)
        location.reload();
      },
      error: function(error){
        var $appendErr = $('<small class="text-error">' + error.responseJSON.message + '</small>');
        $appendErr.appendTo('.error-message-info');
      }
    })
  })

  $('.create-contact-save').on('click', function(e) {
    var formData = new FormData($('.form-ajax-login')[0]);
    $.ajax({
      type: "POST",
      url: '/contacts',
      data: formData,
      processData: false,
      contentType: false,
      success: function(result) {
        localStorage.setItem("status", "alert-success")
        localStorage.setItem("message", "Data berhasil disimpan")
        location.reload();
      },
      error: function(error){
        var $appendErr = $('<small class="text-error">' + error.responseJSON.message + '</small>');
        $appendErr.appendTo('.error-message-info');
      }
    });
  })

  $('.edit-contact-save').on('click', function(e) {
    var contactId = document.getElementById('user_id').value
    var formData = new FormData($('.form-ajax-login')[0]);
    $.ajax({
      type: "PATCH",
      url: '/contacts/'+contactId,
      data: formData,
      processData: false,
      contentType: false,
      success: function(result) {
        localStorage.setItem("status", "alert-success")
        localStorage.setItem("message", "Data berhasil disimpan")
        location.reload();
      },
      error: function(error){
        var $appendErr = $('<small class="text-error">' + error.responseJSON.message + '</small>');
        $appendErr.appendTo('.error-message-info');
      }
    });
  })

  $('.change-booking').on('change', function(e) {
    $('.info-box.shipper-container').html('');
    $('.selected-shipper').val('');
    var id = $(this).children(":selected").attr("value");
    $.ajax({
      type: "GET",
      url: '/contacts/' + id,
      success: function(result) {
        if (!id) {
          $('.selected-shipper').val('');
          return $('.info-box.shipper-container').html('');
        }

        $('.selected-shipper').val(result.id);
        $('#shipper_id').val(result.id);
        $('.info-box.shipper-container').append([
          '<span>' + result.title + ' ' + result.first_name + ' ' + result.last_name + '</span>',
          '<br/>',
          '<small>' +
            result.address +
            '<br/>' +
            result.city +
            '<br/>' +
            result.country + ', ' + result.postal_code +
            '<br/>' +
            '<b>' + result.company_name + '</b>' +
            '<br/>' +
            result.email +
            '<br/>' +
            result.phone_number +
          '</small>'
        ])
      },
      error: function(error){
        console.log(error.responseText)
      }
    });
  })

  $('.change-consignee').on('change', function(e) {
    $('.info-box.consignee-container').html('');
    $('.selected-consignee').val('');
    var id = $(this).children(":selected").attr("value");
    $.ajax({
      type: "GET",
      url: '/contacts/' + id,
      success: function(result) {
        if (!id) {
          $('.selected-consignee').val('');
          return $('.info-box.consignee-container').html('');
        }

        $('.selected-consignee').val(result.id);
        $('#consignee_id').val(result.id);
        $('.info-box.consignee-container').append([
          '<span>' + result.title + ' ' + result.first_name + ' ' + result.last_name + '</span>',
          '<br/>',
          '<small>' +
            result.address +
            '<br/>' +
            result.city +
            '<br/>' +
            result.country + ', ' + result.postal_code +
            '<br/>' +
            '<b>' + result.company_name + '</b>' +
            '<br/>' +
            result.email +
            '<br/>' +
            result.phone_number +
          '</small>'
        ])
      },
      error: function(error){
        console.log(error.responseText)
      }
    });
  })

  $('.lorem').on('click', function(e) {
    e.preventDefault();
    var contactId = $(this).attr("data-id");
    $.ajax({
      type: "GET",
      url: '/contacts/' + contactId,
      success: function(result) {
        $('#edit-modal').modal('show');
        populateData(result, contactId)
      },
      error: function(error){
        console.log(error.responseText)
      }
    });
  })

  $('.close-profile').on('click', function() {
    window.location.href = "/users/profile"
  })

  $('.hapus').on('click', function(e) {
    e.preventDefault();
    var contactId = $(this).attr("data-id");
    showDeleteModal('show', contactId)
  })

  $('.delete-contact-save').on('click', function(e) {
    e.preventDefault();
    var id = document.getElementById('deleted_user_id').value
    $.ajax({
      type: "DELETE",
      url: '/contacts/' + id,
      success: function(result) {
        localStorage.setItem("status", "alert-success")
        localStorage.setItem("message", "Data berhasil disimpan")
        location.reload();
      },
      error: function(error){
        console.log(error.responseText)
      }
    });
  })

  $('.back-to-quotation').on('click', function(e) {
    window.location.href = '/#cekharga'
  })

  $("#hargadate").on('input', function(e) {
    this.type = 'text';
    var input = this.value;
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    var values = input.split('/').map(function(v) {
      return v.replace(/\D/g, '')
    });
    if (values[0]) values[0] = checkValue(values[0], 31);
    if (values[1]) values[1] = checkValue(values[1], 12);
    var output = values.map(function(v, i) {
      return v.length == 2 && i < 2 ? v + ' / ' : v;
    });
    this.value = output.join('').substr(0, 14);
  });

  $("#hargadate").on('blur', function(e) {
    this.type = 'text';
    var input = this.value;
    var values = input.split('/').map(function(v, i) {
      return v.replace(/\D/g, '')
    });
    var output = '';

    if (values.length == 3) {
      var year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
      var month = parseInt(values[0]) - 1;
      var day = parseInt(values[1]);
      var d = new Date(year, month, day);
      if (!isNaN(d)) {
        document.getElementById('result').innerText = d.toString();
        var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
        output = dates.map(function(v) {
          v = v.toString();
          return v.length == 1 ? '0' + v : v;
        }).join(' / ');
      };
    };
    this.value = output;
  });

});


$(function(){
  $('#submit-convert-booking').click(function() {
    $('#form-convert-booking').submit();
  });
});